import React from 'react'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { CustomTextField, NumericField } from 'shared'
import { DATE_FORMAT, MAX_DATE, MIN_DATE, PRICE_FORMAT, STRING_EMPTY } from 'utils/constants'
import * as Yup from 'yup'

import { useStyles } from './DiscountCodeForm.style'

const FORM_STATES = {
  FORM_CREATE: 'create',
  FORM_UPDATE: 'update'
}

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const DiscountCodeForm = ({ values, mode = FORM_STATES.FORM_CREATE, onSubmit }) => {
  const classes = useStyles()

  const { eventDiscountCodes, event } = useSelector((state) => state.events)

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .required('Debe ingresar el nombre')
        .min(3, 'Debe ingresar al menos 3 caracteres')
        .max(15, 'El nombre tiene un máximo de 15 caracteres')
        .matches(/^[a-z0-9.\-_]+$/i, 'El nombre no debe tener caracteres especiales ni espacios')
        .test(
          'test-unique-discount-code',
          'Este nombre ya se encuentra en uso',
          (value) =>
            !eventDiscountCodes.some(
              (x) =>
                x.id !== values.id && x.name.toUpperCase().trim() === value?.toUpperCase().trim()
            )
        )
        .trim(),
      totalCount: Yup.number()
        .typeError('Ingrese un número válido')
        .required('Debe ingresar la cantidad')
        .integer('Ingrese un número entero')
        .max(100, 'Puede ser usado hasta 100 veces')
        .test(
          'test-negative',
          'La cantidad tiene que ser un número entero positivo.',
          (value = 0) => value > 0
        ),
      fixedAmount: Yup.number().when('discountType', {
        is: (discountType) => discountType === DISCOUNT_TYPE.FIXED_AMOUNT,
        then: (schema) =>
          schema
            .required('Debe ingresar el importe de descuento')
            .typeError('Ingrese un número válido')
            .test(
              'test-negative',
              'El importe tiene que ser un número entero positivo.',
              (value = 0) => value > 0
            )
            .max(999999.99, 'Se puede ingresar hasta 6 dígitos')
      }),
      discountType: Yup.string().required('Debe escoger el tipo de descuento'),
      percentage: Yup.number().when('discountType', {
        is: (discountType) => discountType === DISCOUNT_TYPE.PERCENTAGE,
        then: (schema) =>
          schema
            .typeError('Ingrese un número válido')
            .required('Debe ingresar el porcentaje de descuento')
            .test(
              'test-negative',
              'El porcentaje de descuento tiene que ser un número entero positivo.',
              (value = 0) => value > 0
            )
            .max(100, 'El porcentaje máximo de descuento es 100%')
            .test('test-limited-decimal', 'Solo puede ingresar hasta 2 decimales', (value) => {
              const regex = /^([0-9]{1,10}(\.[0-9]{1,2})?)$/
              return regex.test(value?.toString())
            })
      }),
      validFrom: Yup.mixed()
        .nullable()
        .required('Debe ingresar una fecha de inicio del descuento')
        .test('valid-date', 'Formato de fecha inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .when(['validTo'], {
          is: (validTo) => !!validTo && moment(validTo).isValid(),
          then: (schema) =>
            schema.test(
              'from-max',
              'La fecha de inicio debe ser menor a la fecha de cierre',
              (value, { parent }) => moment(value).isBefore(moment(moment(parent.validTo)))
            )
        }),
      validTo: Yup.mixed()
        .nullable()
        .required('Debe ingresar la fecha de fin del descuento')
        .test('valid-date', 'Formato de fecha inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .test(
          'validTo before to event',
          `La fecha debe ser igual o anterior al ${moment(event.to).format('DD/MM/YYYY')}`,
          (value) =>
            moment(moment(value).format(DATE_FORMAT)).isSameOrBefore(
              moment(event.to).format(DATE_FORMAT),
              DATE_FORMAT
            )
        )
        .when(['validFrom'], {
          is: (validFrom) => !!validFrom && moment(validFrom).isValid(),
          then: (schema) =>
            schema.test(
              'to-min',
              'La fecha de cierre debe ser mayor a la fecha de inicio',
              (value, { parent }) => moment(value).isAfter(moment(parent.validFrom))
            )
        })
    },
    ['validFrom', 'validTo']
  )

  const PriceInput = (props) => {
    const { inputRef, onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value !== STRING_EMPTY ? Number(values.value) : ''
            }
          })
        }}
        {...PRICE_FORMAT}
      />
    )
  }
  const handleChangeDateTime = (setValues, field, time, format) => (date) =>
    setValues((values) => ({
      ...values,
      [field]: moment(date).isValid()
        ? (time
            ? moment(`${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`)
            : moment(date)
          ).format(format)
        : date
    }))

  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setValues,
        handleSubmit,
        isSubmitting,
        isValid
      }) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.groupBlock}>
            <div className={classes.formGroup}>
              <Typography className={classes.label} color='primary' variant='h6'>
                Nombre*
              </Typography>
              <CustomTextField
                color='primary'
                variant='outlined'
                value={values.name}
                placeholder='Ingrese nombre del código'
                name='name'
                onChange={(e) => {
                  const { value } = e.target
                  e.target.value = value?.toUpperCase()

                  handleChange(e)
                }}
                helperText={errors.name}
                error={touched.name && Boolean(errors.name)}
                onBlur={handleBlur}
                className={classes.textField}
              />
            </div>
            <div className={classes.formGroup}>
              <Typography className={classes.label} color='primary' variant='h6'>
                Cantidad*
              </Typography>
              <NumericField
                placeholder='Ingrese cant. de descuentos'
                fullWidth
                color='primary'
                variant='outlined'
                className={classes.textField}
                value={values.totalCount}
                name='totalCount'
                helperText={errors.totalCount}
                error={touched.totalCount && Boolean(errors.totalCount)}
                onChange={handleChange}
                onBlur={handleBlur}
                pattern={/^[0-9]*$/}
              />
            </div>
          </div>
          <div className={classes.formGroup}>
            <Typography className={classes.label} color='primary' variant='h6'>
              Descuento*
            </Typography>
            <RadioGroup value={values.discountType} onChange={handleChange} name='discountType'>
              <FormControlLabel
                value='FixedAmount'
                classes={{ label: classes.radioLabel }}
                control={<Radio color='primary' className={classes.radio} />}
                label='Importe'
              />
              <FormControlLabel
                value='Percentage'
                classes={{ label: classes.radioLabel }}
                control={<Radio color='primary' className={classes.radio} />}
                label='Porcentaje'
              />
            </RadioGroup>
            {errors.discountType && (
              <Typography color='error' variant='caption' className={classes.messageError}>
                {errors.discountType}
              </Typography>
            )}

            {values.discountType === DISCOUNT_TYPE.FIXED_AMOUNT && (
              <div className={clsx(classes.formGroup, classes.numericGroup)}>
                <Typography color='primary' variant='h6' className={classes.label}>
                  Importe
                </Typography>
                <TextField
                  value={values.fixedAmount}
                  placeholder='$'
                  variant='outlined'
                  name='fixedAmount'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    inputComponent: PriceInput
                  }}
                  error={touched.fixedAmount && Boolean(errors.fixedAmount)}
                  helperText={errors.fixedAmount}
                  className={classes.textField}
                />
              </div>
            )}
            {values.discountType === DISCOUNT_TYPE.PERCENTAGE && (
              <div className={clsx(classes.formGroup, classes.numericGroup)}>
                <Typography color='primary' variant='h6' className={classes.label}>
                  Porcentaje
                </Typography>
                <NumericField
                  value={values.percentage}
                  placeholder='%'
                  variant='outlined'
                  name='percentage'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: !!values.percentage && (
                      <InputAdornment position='start' className={classes.percentageAdornment}>
                        %
                      </InputAdornment>
                    )
                  }}
                  error={touched.percentage && Boolean(errors.percentage)}
                  helperText={errors.percentage}
                  className={clsx(
                    classes.textField,
                    !!values.percentage && classes.percentageField
                  )}
                />
              </div>
            )}
          </div>
          <div className={classes.formGroup}>
            <Typography color='primary' variant='h6' className={classes.validDiscountTitle}>
              Validez del descuento
            </Typography>

            <div className={classes.groupBlock}>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6' className={classes.label}>
                  Desde
                </Typography>
                <KeyboardDatePicker
                  className={clsx(classes.inputDateField, classes.dateField)}
                  autoOk
                  name='validFrom'
                  format='DD/MM/YYYY'
                  variant='inline'
                  margin='none'
                  id='date-picker-inline'
                  inputVariant='outlined'
                  onBlur={handleBlur}
                  value={values.validFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDateMessage=''
                  maxDateMessage=''
                  invalidDateMessage=''
                  onChange={handleChangeDateTime(
                    setValues,
                    'validFrom',
                    moment('00:00', 'HH:mm').format(),
                    'YYYY-MM-DD'
                  )}
                  placeholder='ddmmaaaa'
                  error={touched.validFrom && Boolean(errors.validFrom)}
                />
                {errors.validFrom && (
                  <Typography color='error' variant='caption' className={classes.messageError}>
                    {errors.validFrom}
                  </Typography>
                )}
              </div>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6' className={classes.label}>
                  Hasta
                </Typography>
                <KeyboardDatePicker
                  className={clsx(classes.inputDateField, classes.dateField)}
                  autoOk
                  name='validTo'
                  format='DD/MM/YYYY'
                  variant='inline'
                  margin='none'
                  id='date-picker-inline'
                  inputVariant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChangeDateTime(
                    setValues,
                    'validTo',
                    moment('23:59', 'HH:mm').format()
                  )}
                  value={values.validTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDateMessage=''
                  maxDateMessage=''
                  invalidDateMessage=''
                  placeholder='ddmmaaaa'
                  error={touched.validTo && Boolean(errors.validTo)}
                />
                {errors.validTo && (
                  <Typography color='error' variant='caption' className={classes.messageError}>
                    {errors.validTo}
                  </Typography>
                )}
              </div>
            </div>
            <Typography className={classes.validDateHelperText}>
              La validez del código es desde las 00:00 del 1° día, hasta las 23:59 del segundo día
              (en caso que fuera más de un día).
            </Typography>
          </div>

          {mode === FORM_STATES.FORM_CREATE && (
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              type='submit'
              disabled={!isValid || isSubmitting}
              endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}>
              Crear
            </Button>
          )}

          {mode === FORM_STATES.FORM_UPDATE && (
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              type='submit'
              disabled={!isValid || isSubmitting}
              endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}>
              Guardar
            </Button>
          )}
        </form>
      )}
    </Formik>
  )
}

export default DiscountCodeForm
