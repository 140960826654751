import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core'
import { DeleteOutlined as DeleteOutlineIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { ActionDialog } from 'pages/Events/InscriptionPage/components'
import { deleteCategory, deleteDistance, loadCategories, loadDistances } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { DeleteItemNotifyDialog } from '../DeleteItemNotifyDialog'

import { useStyles } from './DeleteCategoryDialog.style'

const DELETE_ITEM_NOTIFY_DIALOG_STATES = {
  DELETE_CATEGORY_CONFIRM: 'deleteCategoryConfirm',
  DELETE_CATEGORY_NOT_ALLOWED: 'deleteCategoryNotAllowed'
}

const DeleteCategoryDialog = ({ eventId, field, distances }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values: formState, setValues } = useFormikContext()

  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteNotifyDialog, setOpenDeleteNotifyDialog] = useState(false)
  const [deleteCategoryNotifyProps, setDeleteCategoryNotifyProps] = useState({
    state: DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CATEGORY_NOT_ALLOWED
  })

  const values = {
    name: '',
    shortName: '',
    ageRangeMin: '',
    ageRangeMax: '',
    ageNoLimit: false,
    gender: '',
    ...field
  }

  const handleDeleteCategory = async (value) => {
    if (categoryRelatedWithDistance.length) {
      for (const { distance } of categoryRelatedWithDistance) {
        await dispatch(deleteDistance(eventId, distance.id))
      }

      await dispatch(loadDistances(eventId))
    }

    await dispatch(deleteCategory(eventId, value.id))
    const categories = await dispatch(loadCategories(eventId))

    setValues((state) => {
      const distancesRelated = state.items.filter((x) => x.eventCategoryId === values.id)

      const items = state.items
        .map((x) =>
          distancesRelated.some((d) => d.eventDistanceId === x.eventDistanceId)
            ? { ...x, hasDistanceChanges: true }
            : x
        )
        .filter((x) => x.eventCategoryId !== values.id)

      const hasCategoriesChanges =
        state.items.length !== items.length ||
        state.ticketTypes.some((x) =>
          x.items.some((i) => i.inscriptionFormItem.eventCategoryId === values.id)
        )

      return {
        ...state,
        items,
        categories,
        hasCategoriesChanges,
        ticketTypes: state.ticketTypes.map((x) => {
          const items = x.items.filter((i) => i.inscriptionFormItem.eventCategoryId !== values.id)

          return {
            ...x,
            hasChanges:
              items.length !== x.items.length ||
              x.items.some(
                (x) =>
                  !items.some(
                    (i) =>
                      i.inscriptionFormItem.eventCategoryId ===
                        x.inscriptionFormItem.eventCategoryId &&
                      i.inscriptionFormItem.eventDistanceId ===
                        x.inscriptionFormItem.eventDistanceId
                  )
              ),
            items
          }
        })
      }
    })

    showSnackbarSuccess('¡Categoria eliminada con éxito!')
    return true
  }

  const handleOpenDeleteCategoryDialog = () => {
    const hasBindTicket = formState.ticketTypes.some((x) =>
      x.items.some((i) => i.inscriptionFormItem.eventCategoryId === values.id)
    )

    if (!hasBindTicket) return setOpenDialog(true)

    const stateConditional = formState.ticketTypes.some(
      (x) =>
        x.items.filter(
          (i) => !!i.inscriptionFormItem && i.inscriptionFormItem.eventCategoryId !== values.id
        ).length < 1
    )

    setDeleteCategoryNotifyProps({
      ...deleteCategoryNotifyProps,

      state: stateConditional
        ? DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CATEGORY_CONFIRM
        : DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CATEGORY_NOT_ALLOWED,

      category: values,

      ...(stateConditional
        ? {
            onAccept: () => setOpenDeleteNotifyDialog(false)
          }
        : {
            onClose: () => setOpenDeleteNotifyDialog(false)
          })
    })

    setOpenDeleteNotifyDialog(true)
  }

  const categoryRelatedWithDistance = distances.filter(
    (d) => d.categories.length === 1 && d.categories[0].id === values.id
  )

  return (
    <>
      <IconButton
        color='primary'
        title='Eliminar categoría'
        onClick={handleOpenDeleteCategoryDialog}>
        <DeleteOutlineIcon />
      </IconButton>
      <ActionDialog
        open={openDialog}
        className={classes.dialog}
        values={values}
        formClassName={classes.formContainer}
        action={handleDeleteCategory}
        title={
          categoryRelatedWithDistance.length
            ? 'ELIMINAR CATEGORÍA ASOCIADA A UNA DISTANCIA'
            : 'ELIMINAR CATEGORÍA'
        }>
        {({ values, isValid, isSubmitting }) => (
          <>
            <div className={classes.deleteDialogBody}>
              <Typography variant='h6' align='center' color='primary' className={classes.subTitle}>
                {categoryRelatedWithDistance.length
                  ? `Al eliminar la categoría ${values.name} se eliminarán las distancias asociadas.`
                  : `¿Está seguro que desea eliminar del evento la categoría: ${values.name}?`}
              </Typography>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.actionButton}
                  color='primary'
                  variant='contained'
                  onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
                <Button
                  className={classes.actionButton}
                  color='primary'
                  variant='contained'
                  endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
                  type='submit'
                  disabled={!isValid || isSubmitting}>
                  Eliminar
                </Button>
              </div>
            </div>
          </>
        )}
      </ActionDialog>
      <DeleteItemNotifyDialog
        open={openDeleteNotifyDialog}
        onSubmit={() => handleDeleteCategory(values)}
        {...deleteCategoryNotifyProps}
      />
    </>
  )
}

export default DeleteCategoryDialog
