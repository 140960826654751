import React from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemText-root': {
      marginRight: theme.spacing()
    },
    '&:hover, &:disabled': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  containerBody: {
    backgroundColor: '#FFFFFF'
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    whiteSpace: 'nowrap',

    '&.adminMode': {
      flexDirection: 'column-reverse',
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: 0,
      width: '100%',
      gap: theme.spacing(3),
      '&.results': {
        flexDirection: 'column',
        background: theme.palette.background.section,
        borderRadius: 10,
        border: `1px solid ${theme.palette.primary.main}`,
        position: 'relative',
        '& > div': {
          width: 'auto',
          backgroundColor: 'transparent',
          marginTop: 0
        },
        '& > div > div': {
          backgroundColor: 'transparent'
        },
        overflowX: 'hidden'
      }
    },
    '&.suspended': {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex'
      }
    }
  },

  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: '95%',
      '&.row': {
        display: 'flex'
      }
    },
    [theme.breakpoints.up('xl')]: {
      width: '80%'
    }
  },
  resultsContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      '&.results': {
        width: '65%'
      },
      flexShrink: 0
    },
    '&.suspended': {
      display: 'none'
    }
  },

  titleContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '80%',
    margin: '0 auto',
    '&.results': {
      padding: theme.spacing(2, 0)
    },

    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    '&.results': {
      fontWeight: 600,
      fontSize: '1.375rem'
    },
    [theme.breakpoints.up('lg')]: {
      color: '#ffffff',
      fontSize: '1.3rem'
    }
  },

  leftColumn: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      flexGrow: 1,
      '&.results': {
        flexGrow: 0
      }
    },
    '&.suspended': {
      display: 'none'
    }
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#D9D9D966',
    borderRadius: 10,
    '& > div': {
      backgroundColor: 'transparent !important'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
      width: 450,
      flexShrink: 0,
      alignItems: 'center'
    }
  },

  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    ' & h5': {
      fontWeight: 600
    }
  },

  resultsHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',

    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  organizationTitle: {
    fontWeight: 700,
    margin: theme.spacing(0, 3),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },

  asideContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    gap: theme.spacing(4)
  },
  publishButton: {
    width: 285,
    height: 43,
    borderRadius: 20,
    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem'
    }
  },
  editButton: {
    width: 285,
    height: 43,
    borderRadius: 20,
    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem',
      color: `${theme.palette.primary.contrastText} !important`
    }
  },
  previewViewLink: {
    fontWeight: 700,
    fontSize: '1.25rem',
    color: theme.palette.primary.main
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    fontSize: '.875rem',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 330
    }
  },
  infoIcon: {
    width: 23,
    height: 23
  },
  publishedEventTitle: {
    fontWeight: 700,
    fontSize: '1.25rem'
  },
  resultsTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  }
}))

export default useStyles
