import React from 'react'
import { Typography } from '@material-ui/core'
import { EditableRichText, EditableSection } from 'shared'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './DescriptionSection.style'

const DescriptionSection = ({ event, afterEvent, canEdit, isMobile, ...sectionProps }) => {
  const classes = useStyles()

  return (
    <EditableSection entity={event} {...sectionProps}>
      {({ errors }) => (
        <div className={classes.section}>
          {!canEdit &&
            ((event.hasResults && !event.hasResultsSheetFile && !isMobile) ||
              (!event.hasResults && afterEvent)) && (
              <Typography className={classes.title} color='primary' variant='h6'>
                Evento {afterEvent ? 'finalizado' : 'en curso'}
              </Typography>
            )}
          <SectionBlock title='Descripción'>
            <EditableRichText
              entity={event}
              field='description'
              activeModeEdit={false}
              inputClassName={classes.textAreaEdit}
              repositoryId={event.id}
              errors={errors.description}
            />
          </SectionBlock>
        </div>
      )}
    </EditableSection>
  )
}

export default DescriptionSection
