import React from 'react'
import { Switch, withStyles } from '@material-ui/core'
import clsx from 'clsx'

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 43,
    height: 19,
    padding: 0,
    margin: theme.spacing(1),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 9
  },
  switchBase: {
    padding: '3px 5px',

    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.primary.main,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,

        opacity: 1,
        border: 'none'
      },

      '&:disabled': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '&$focusVisible $thumb': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  thumb: {
    width: 18,
    height: 13,

    color: theme.palette.primary.contrastText,
    borderRadius: 9
  },
  track: {
    borderRadius: 9,
    backgroundColor: theme.palette.disabled.main,
    opacity: 1,
    '&.disabled': {
      backgroundColor: `${theme.palette.disabled.main} !important`
    }
  },
  checked: {},
  focusVisible: {}
}))(({ classes, disabled, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: clsx(classes.track, disabled && 'disabled'),
        checked: classes.checked
      }}
      {...props}
    />
  )
})

export default StyledSwitch
