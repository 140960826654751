import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './EventCreatedDialog.style'

const EventCreatedDialog = ({ open, onClose }) => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  return (
    <SectionLayoutDialog
      title='Evento creado exitosamente'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <div className={classes.container}>
        <Typography variant='h6' className={classes.text}>
          Has creado exitosamente el evento <strong>{event.name}.</strong>
        </Typography>
        <Typography variant='h6' color='error' className={classes.text}>
          Para que tu evento sea visible para todos los usuarios deberás hacer click en&nbsp;
          <strong>Publicar evento</strong>.
        </Typography>
        <Typography variant='h6' className={classes.text}>
          Te recomendamos que completes todos los campos que aportan información importante al
          deportista, tales como reglamento, circuito, detalle de cronograma, para darle mayor
          visibilidad.
        </Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default EventCreatedDialog
