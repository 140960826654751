import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    margin: '10px auto',
    backgroundColor: '#ffff',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '93%',
      minWidth: 277,
      maxWidth: 312.5
    },
    '&.createCard': {
      height: 267
    }
  },
  container: {
    textDecoration: 'none'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '70%'
  },
  headerContainer: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: '#ffff',
    justifyContent: 'space-between',
    height: 55,
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    '&.suspended': {
      backgroundColor: 'rgba(200, 200, 200, 1)'
    }
  },
  headerContainerResult: {
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.7),
    color: theme.palette.primary.main
  },
  bodyContainer: {
    padding: theme.spacing(2, 1)
  },

  featureImageContainer: {
    width: '100%',
    height: '39vw',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 152,
      height: '9.5vw',
      minHeight: 135
    }
  },
  featureImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  actionButtonContainer: {
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    color: '#ffff',
    width: '100%',
    display: 'flex',
    height: 50,
    padding: theme.spacing(0, 1),
    '&.suspended': {
      backgroundColor: 'rgba(200, 200, 200, 1)'
    },
    '&.center': {
      justifyContent: 'center'
    }
  },
  actionButton: {
    color: '#ffff',
    '& em': {
      fontSize: '0.75em',
      fontWeight: 200,
      fontStyle: 'normal',
      fontFamily: theme.typography.fontFamily,
      marginLeft: theme.spacing()
    },
    '&:disabled': {
      color: '#ffff'
    }
  },
  actionSocialBarButton: {
    flexGrow: 1
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '50%',
    '& p': {
      color: 'rgba(105, 105, 105, 1)',
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
    }
  },
  date: {
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  sportContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0)
  },
  sport: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  nameEventContainer: {
    padding: '12px 0px',
    height: 60,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    display: 'flex',
    alignItems: 'center'
  },
  nameEvent: {
    fontWeight: 500,
    fontSize: '.9rem',
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    '&.suspended': {
      color: 'rgba(105, 105, 105, 1)'
    }
  },
  link: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  messageContainer: {
    padding: theme.spacing(1),
    height: 65,
    display: 'flex',
    alignItems: 'center',
    '& h5': {
      fontWeight: 600
    }
  },
  description: {
    fontSize: '.9rem',
    margin: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    '&.suspended': {
      color: 'rgba(200, 200, 200, 1)',
      '& strong': {
        color: 'rgba(200, 200, 200, 1)'
      }
    },
    '& strong': {
      color: theme.palette.primary.main,
      fontWeight: 400
    }
  },
  loadResultContainer: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    height: 33,
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontWeight: 600
    },
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.7),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    color: theme.palette.primary.main
  },
  shareButton: {
    color: theme.palette.primary.contrastText,
    borderRadius: 15,
    '& svg': {
      color: theme.palette.primary.contrastText
    },
    '&.suspended': {
      color: theme.palette.primary.contrastText
    },
    '&.alt': {
      marginBottom: theme.spacing(),
      borderRadius: 70,
      '& svg': {
        color: `${theme.palette.primary.main} !important`
      },
      color: `${theme.palette.primary.main} !important`
    }
  },
  textContainer: {
    height: 115,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2)
  },
  organizationContainer: {
    padding: theme.spacing(1, 0)
  },
  organization: {
    fontSize: '0.9rem',
    overflow: 'hidden',
    color: 'rgba(105, 105, 105, 1)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& span': {
      color: theme.palette.primary.main
    }
  },
  endContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  linkInscription: {
    textDecoration: 'none',
    backgroundColor: theme.palette.secondary.main,
    width: 150,
    marginRight: 15,
    textAlign: 'center',
    borderRadius: 10,
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  running: {
    '&.size-2': {
      backgroundPositionX: '27%'
    },
    '&.size-3': {
      backgroundPositionX: '32%'
    }
  },
  'mountain-bike': {
    '&.size-2': {
      backgroundPositionX: '46%'
    },
    '&.size-3': {
      backgroundPositionX: '60%'
    }
  },
  natacion: {
    '&.size-2': {
      backgroundPositionX: '55%'
    },
    '&.size-3': {
      backgroundPositionX: '70%'
    }
  },
  biking: {
    '&.size-2': {
      backgroundPositionX: '1%'
    },
    '&.size-3': {
      backgroundPositionX: '15%'
    }
  },
  fbi: {
    '&.size-2': {
      backgroundPositionX: '52%'
    }
  },
  kayak: {
    '&.size-2': {
      backgroundPositionX: '38%'
    },
    '&.size-3': {
      backgroundPositionX: '30%'
    }
  },
  automovilismo: {
    '&.size-2': {
      backgroundPositionX: '42%'
    },
    '&.size-3': {
      backgroundPositionX: '26%'
    }
  },
  motocross: {
    '&.size-2': {
      backgroundPositionX: '97%'
    },
    '&.size-3': {
      backgroundPositionX: '91%'
    }
  }
}))
