import * as types from './types'

// InitialState
const initialState = {
  userAccount: null,
  pending: false,

  searchParams: {
    searchText: '',
    page: 1
  },
  searchUserAccounts: [],
  searchUserAccountsPageNumber: 1,
  searchUserAccountsLastPage: false,
  searchMetadataPending: false,
  searchMetadata: null,
  organizations: [],
  calendarEvents: [],
  loadingOrganizations: false,
  results: [],
  loadingResults: false,
  settingImage: false,
  pendingCalendar: false,
  followPending: false,
  error: null,

  followedUserAccounts: null,
  followedUserAccountsPending: false,

  followedOrganizations: null,
  followedOrganizationsPending: false,

  organizationsAdminAll: [],
  organizationsAdminAllPending: false,

  upcomingEventsManaged: [],
  upcomingEventsManagedPending: false,
  upcomingEventsManagedLastPage: false,
  upcomingEventsManagedPageNumber: 1,
  upcomingEventsManagedError: null,

  upcomingEventsByMyOrganization: [],
  upcomingEventsByMyOrganizationPending: false,
  upcomingEventsByMyOrganizationLastPage: false,
  upcomingEventsByMyOrganizationPageNumber: 1,
  upcomingEventsByMyOrganizationError: null,

  pastEventsByMyOrganization: [],
  pastEventsByMyOrganizationPending: false,
  pastEventsByMyOrganizationLastPage: false,
  pastEventsByMyOrganizationPageNumber: 1,
  pastEventsByMyOrganizationError: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_SEARCH:
      return {
        ...state,
        error: null,
        searchUserAccounts: [],
        searchUserAccountsPageNumber: 1,
        searchUserAccountsLastPage: false
      }
    case types.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.searchParams
      }
    case types.SEARCH_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }

    case types.SEARCH_SUCCESS:
      return {
        ...state,
        searchUserAccounts: [...state.searchUserAccounts, ...action.userAccountsPage.results],
        searchUserAccountsPageNumber: action.userAccountsPage.currentPage,
        searchUserAccountsLastPage:
          action.userAccountsPage.pageCount <= action.userAccountsPage.currentPage,
        pending: false
      }
    case types.SEARCH_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.SEARCH_METADATA_ATTEMPT:
      return {
        ...state,
        searchMetadataPending: true
      }
    case types.SEARCH_METADATA_SUCCESS:
      return {
        ...state,
        searchMetadataPending: false,
        searchMetadata: action.searchMetadata
      }
    case types.SEARCH_METADATA_FAILURE:
      return {
        ...state,
        searchMetadataPending: false,
        error: action.error
      }
    case types.RESET_DATA:
      return {
        ...state,
        searchUserAccounts: []
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        userAccount: null,
        error: null,
        pending: true
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        userAccount: action.userAccount,
        pending: false
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        userAccount: null,
        pending: false,
        error: action.error
      }
    case types.LOAD_RESULTS_ATTEMPT:
      return {
        ...state,
        results: [],
        loadingResults: true
      }
    case types.LOAD_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.results,
        loadingResults: false
      }
    case types.LOAD_RESULTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false
      }
    case types.LOAD_ORGANIZATIONS_ATTEMPT:
      return {
        ...state,
        organizations: null,
        loadingOrganizations: true
      }
    case types.LOAD_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.organizations,
        loadingOrganizations: false
      }
    case types.LOAD_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingOrganizations: false
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        pending: true
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        userAccount: action.userAccount,
        pending: false
      }
    case types.SET_IMAGE_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true,
        settingImage: true
      }
    case types.SET_IMAGE_SUCCESS:
      return {
        ...state,
        settingImage: false,
        pending: false,
        userAccount: action.userAccount
      }
    case types.SET_IMAGE_FAILURE:
      return {
        ...state,
        pending: false,
        settingImage: false,
        error: action.error
      }
    case types.LOAD_RESET:
      return {
        ...state,
        userAccount: null,
        results: [],
        organizations: null,
        error: null
      }
    case types.LOAD_CALENDAR_ATTEMPT:
      return {
        ...state,
        pendingCalendar: true,
        calendarEvents: []
      }
    case types.LOAD_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarEvents: action.calendarEvents,
        pendingCalendar: false
      }
    case types.LOAD_CALENDAR_FAILURE:
      return {
        ...state,
        pendingCalendar: false,
        error: action.error
      }
    case types.FOLLOW_ATTEMPT:
      return {
        ...state,
        followPending: true
      }
    case types.FOLLOW_SUCCESS:
      return {
        ...state,
        userAccount: action.userAccount,
        followPending: false
      }
    case types.FOLLOW_FAILURE:
      return {
        ...state,
        error: action.error,
        followPending: false
      }
    case types.LOAD_FOLLOWED_USER_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        followedUserAccounts: null,
        followedUserAccountsPending: true
      }
    case types.LOAD_FOLLOWED_USER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        followedUserAccounts: action.followedUserAccounts,
        followedUserAccountsPending: false
      }
    case types.LOAD_FOLLOWED_USER_ACCOUNTS_FAILURE:
      return {
        ...state,
        followedUserAccounts: null,
        error: action.error,
        followedUserAccountsPending: false
      }
    case types.LOAD_FOLLOWED_ORGANIZATIONS_ATTEMPT:
      return {
        ...state,
        followedOrganizations: null,
        followedOrganizationsPending: true
      }
    case types.LOAD_FOLLOWED_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        followedOrganizations: action.followedOrganizations,
        followedOrganizationsPending: false
      }
    case types.LOAD_FOLLOWED_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        followedOrganizations: null,
        followedOrganizationsPending: false
      }
    case types.DELETE_IMAGE_ATTEMPT:
      return {
        ...state,
        settingImage: true
      }
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        userAccount: action.userAccount,
        settingImage: false
      }
    case types.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        settingImage: false
      }
    case types.RESET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          searchText: '',
          page: 1
        },
        searchUserAccounts: [],
        pending: false,
        searchUserAccountsLastPage: false,
        searchUserAccountsPageNumber: 1
      }
    case types.LOAD_ORGANIZATIONS_ADMIN_ALL_ATTEMPT:
      return {
        ...state,
        organizationsAdminAllPending: true
      }
    case types.LOAD_ORGANIZATIONS_ADMIN_ALL_SUCCESS:
      return {
        ...state,
        organizationsAdminAll: action.organizationsAdmin,
        organizationsAdminAllPending: false
      }
    case types.LOAD_ORGANIZATIONS_ADMIN_ALL_FAILURE:
      return {
        ...state,
        organizationsAdminAll: [],
        organizationsAdminAllPending: false
      }

    case types.LOAD_UPCOMING_EVENT_MANAGED_BY_ATTEMPT:
      return {
        ...state,
        upcomingEventsManagedPending: true,
        upcomingEventsManagedError: null
      }

    case types.LOAD_UPCOMING_EVENT_MANAGED_BY_SUCCESS:
      return {
        ...state,
        upcomingEventsManaged: [
          ...state.upcomingEventsManaged,
          ...action.upcomingEventsManaged.results
        ],
        upcomingEventsManagedPageNumber: action.upcomingEventsManaged.currentPage,
        upcomingEventsManagedLastPage:
          action.upcomingEventsManaged.pageCount <= action.upcomingEventsManaged.currentPage,
        upcomingEventsManagedPending: false
      }

    case types.LOAD_UPCOMING_EVENT_MANAGED_BY_FAILURE:
      return {
        ...state,
        upcomingEventsManaged: [],
        upcomingEventsManagedPending: false,
        upcomingEventsManagedError: action.error
      }

    case types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_ATTEMPT:
      return {
        ...state,
        upcomingEventsByMyOrganizationPending: true,
        upcomingEventsByMyOrganizationError: null
      }

    case types.RESET_UPCOMING_EVENT_MANAGED_BY:
      return {
        ...state,
        upcomingEventsManaged: [],
        upcomingEventsManagedError: null,
        upcomingEventsManagedLastPage: false,
        upcomingEventsManagedPageNumber: 1
      }

    case types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        upcomingEventsByMyOrganization: [
          ...state.upcomingEventsByMyOrganization,
          ...action.upcomingEventsByMyOrganization.results
        ],
        upcomingEventsByMyOrganizationPageNumber: action.upcomingEventsByMyOrganization.currentPage,
        upcomingEventsByMyOrganizationLastPage:
          action.upcomingEventsByMyOrganization.pageCount <=
          action.upcomingEventsByMyOrganization.currentPage,
        upcomingEventsByMyOrganizationPending: false
      }
    case types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_FAILURE:
      return {
        ...state,
        upcomingEventsByMyOrganizationError: action.error,
        upcomingEventsByMyOrganizationPending: false
      }

    case types.RESET_UPCOMING_EVENTS_BY_MY_ORGANIZATION:
      return {
        ...state,
        upcomingEventsByMyOrganization: [],
        upcomingEventsByMyOrganizationError: null,
        upcomingEventsByMyOrganizationLastPage: false,
        upcomingEventsByMyOrganizationPageNumber: 1
      }

    case types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_ATTEMPT:
      return {
        ...state,
        pastEventsByMyOrganizationPending: true,
        pastEventsByMyOrganizationError: null
      }
    case types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        pastEventsByMyOrganization: [
          ...state.pastEventsByMyOrganization,
          ...action.pastEventsByMyOrganization.results
        ],
        pastEventsByMyOrganizationPageNumber: action.pastEventsByMyOrganization.currentPage,
        pastEventsByMyOrganizationLastPage:
          action.pastEventsByMyOrganization.pageCount <=
          action.pastEventsByMyOrganization.currentPage,
        pastEventsByMyOrganizationPending: false
      }

    case types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_FAILURE:
      return {
        ...state,
        pastEventsByMyOrganizationError: action.error,
        pastEventsByMyOrganizationPending: false
      }

    case types.RESET_PAST_EVENTS_BY_MY_ORGANIZATION:
      return {
        ...state,
        pastEventsByMyOrganization: [],
        pastEventsByMyOrganizationPageNumber: 1,
        pastEventsByMyOrganizationLastPage: false,
        pastEventsByMyOrganizationError: null
      }
    default:
      return state
  }
}
