import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { verifyUserNotLogged } from 'state/modules/app'
import { downloadResultsReport, downloadResultsSheet } from 'state/modules/events'

import { CheckStateInscriptionDialog } from '../CheckStateInscriptionDialog'
import { EventDrawer } from '../EventDrawer'
import { EventResults } from '../EventResults'
import { EventResultsDialog } from '../EventResultsDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ResultsSection.style'

const ResultsSection = ({ event, hasEventResults, canEdit, afterEvent, activeDrawer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const {
    downloadResultsSheetPending,
    downloadResultsReportPending,
    eventResultsReportUrlPending
  } = useSelector((state) => state.events)

  const { user } = useSelector((state) => state.auth)

  const [openEventResultsDialog, setOpenEventResultsDialog] = useState(false)
  const [openCheckStateInscriptionDialog, setOpenCheckStateInscriptionDialog] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(true)

  const {
    activeInscriptionFormId,
    activeInscriptionForm,
    hasResultsSheetFile,
    hasPartialResults,
    externalResultsUrl
  } = event

  const renderInscriptionStateButton =
    !afterEvent &&
    !!activeInscriptionFormId &&
    !activeInscriptionForm?.isDraft &&
    activeInscriptionForm?.allInscriptionsCount > 0

  const getResultsTitleButton = () => {
    if (hasResultsSheetFile) {
      if (hasPartialResults) return 'Descarga result. parciales'
      else return 'Descargar resultados'
    } else {
      if (hasPartialResults) return 'Ver resultados parciales'
      else return 'Ver resultados'
    }
  }

  const handleDownloadResults = (action) => {
    if (action && user) {
      action(event)
    } else {
      dispatch(
        verifyUserNotLogged({
          state: true
        })
      )
    }
  }

  const redirectExternalResultsUrl = () => window.open(event.externalResultsUrl, '_blank')

  const handleEventResults = async () => {
    if (externalResultsUrl) {
      redirectExternalResultsUrl()
    } else if (!hasResultsSheetFile) {
      setOpenEventResultsDialog(true)
    } else {
      dispatch(downloadResultsReport(event))
    }
  }

  return (
    <SectionBlock
      title={hasEventResults && 'Resultados'}
      className={classes.section}
      titleClassName={clsx(isMobile && classes.title)}>
      <EventDrawer
        open={openDrawer}
        position='top'
        setOpen={setOpenDrawer}
        activeDrawer={activeDrawer}
        drawerClassName={classes.drawer}>
        <div className={clsx(classes.mainContainer, hasEventResults && isMobile && 'margin')}>
          {hasEventResults && <EventResults event={event} />}

          <div className={classes.resultsButtonContainer}>
            {isMobile &&
              (canEdit ? (
                hasEventResults && (
                  <Button
                    disabled={downloadResultsSheetPending}
                    className={classes.downloadEventResultsButton}
                    onClick={() =>
                      handleDownloadResults(() => dispatch(downloadResultsSheet(event)))
                    }
                    endIcon={
                      downloadResultsSheetPending && <CircularProgress size={16} color='primary' />
                    }
                    variant='contained'
                    color='secondary'>
                    Descargar resultados
                  </Button>
                )
              ) : (
                <>
                  <Button
                    disabled={downloadResultsReportPending || eventResultsReportUrlPending}
                    className={classes.downloadEventResultsButton}
                    onClick={handleEventResults}
                    endIcon={
                      (downloadResultsReportPending || eventResultsReportUrlPending) && (
                        <CircularProgress size={16} color='primary' />
                      )
                    }
                    variant='contained'
                    color='secondary'>
                    {getResultsTitleButton()}
                  </Button>

                  {renderInscriptionStateButton && (
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => setOpenCheckStateInscriptionDialog(true)}
                      className={classes.inscriptionStateButton}>
                      Estado de mi inscripción
                    </Button>
                  )}
                </>
              ))}
          </div>
        </div>
      </EventDrawer>

      <EventResultsDialog
        open={openEventResultsDialog}
        event={event}
        onClose={() => setOpenEventResultsDialog(false)}
      />

      <CheckStateInscriptionDialog
        open={openCheckStateInscriptionDialog}
        onClose={() => setOpenCheckStateInscriptionDialog(false)}
      />
    </SectionBlock>
  )
}

export default ResultsSection
