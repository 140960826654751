import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import { ROUTES } from 'routes'
import { BannerSection, EventContainer, Footer, MainLayout, NavBar, PageContainer } from 'shared'
import { loadWelcomeUser } from 'state/modules/auth'
import { resetSearchParams as eventsResetSearchParams } from 'state/modules/events'
import {
  loadPastEvents,
  loadUpcomingEvents,
  resetPastEvents,
  resetUpcomingEvents
} from 'state/modules/home'
import { resetSearchParams as organizationsResetSearchParams } from 'state/modules/organizations'
import { resetSearchParams as userAccountsResetSearchParams } from 'state/modules/userAccounts'

import { SearchWidget } from './components'
import useStyles from './HomePage.style'

const SEARCH_STATES = {
  SIMPLE_SEARCH: 'simpleSearch',
  MULTIPLE_SEARCH: 'multipleSearch'
}

const HomePage = () => {
  const NAVBAR_TRANSPARENT_SCROLL = 180
  const {
    upcomingEvents,
    loadingUpcomingEvents,
    pastEvents,
    pastPageNumber,
    loadingPastEvents,
    upcomingPageNumber,
    upcomingLastPage,
    pastLastPage
  } = useSelector((state) => state.home)
  const mediumDevice = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const location = useLocation()
  const { search } = location

  const dispatch = useDispatch()

  const [scrollTop, setScrollTop] = useState(0)
  const [openMiniSearch, setOpenMiniSearch] = useState(false)
  const [openUserOptions, setOpenUserOptions] = useState(false)

  const classes = useStyles()
  useEffect(() => {
    dispatch(loadUpcomingEvents())
    dispatch(loadPastEvents())
    dispatch(eventsResetSearchParams())
    dispatch(userAccountsResetSearchParams())
    dispatch(organizationsResetSearchParams())

    return () => {
      dispatch(resetUpcomingEvents())
      dispatch(resetPastEvents())
    }
  }, [dispatch])

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search)
      const key = query.get('key')
      dispatch(loadWelcomeUser(key))
    }
  }, [dispatch, search])

  const hideBackground = scrollTop < NAVBAR_TRANSPARENT_SCROLL
  const slideMinCount = mediumDevice ? 4 : 1

  return (
    <MainLayout>
      <NavBar
        transparent={!openUserOptions && hideBackground}
        showWidget={true}
        setOpenUserOptions={setOpenUserOptions}
        widget={(props) => (
          <SearchWidget
            mini
            onChangeMiniSearch={setOpenMiniSearch}
            transparent={hideBackground}
            mode={SEARCH_STATES.SIMPLE_SEARCH}
            {...props}
          />
        )}
      />

      <SearchWidget showSearchFull={mediumDevice} showSearchTitle={!openMiniSearch} />
      <PageContainer customContainerClassName={classes.mainContainer}>
        <div className={classes.bodyContainer}>
          <div className={classes.sectionContainer}>
            <EventContainer
              title='PRÓXIMOS EVENTOS'
              events={upcomingEvents}
              autoplay={true}
              infinite={upcomingEvents.length > slideMinCount}
              lastPage={upcomingLastPage}
              nextPage={upcomingPageNumber + 1}
              isLoading={!upcomingEvents.length && loadingUpcomingEvents}
              allPageUrl={ROUTES.EVENTS.UPCOMING_EVENTS}
              prevArrowPosition={{ top: 170, left: -40 }}
              nextArrowPosition={{ top: 170, right: -40 }}
              showOptions={!!upcomingEvents.length}
              fetchNewPage={(page = 1) => dispatch(loadUpcomingEvents({ page }))}
            />
          </div>
          <div className={classes.bannerContainer}>
            <BannerSection />
          </div>
          <div className={classes.sectionContainer}>
            <EventContainer
              title='ÚLTIMOS RESULTADOS'
              events={pastEvents}
              allPageUrl={ROUTES.EVENTS.LATEST_RESULTS_EVENTS}
              prevArrowPosition={{ top: 170, left: -40 }}
              nextArrowPosition={{ top: 170, right: -40 }}
              isLoading={!pastEvents.length && loadingPastEvents}
              nextPage={pastPageNumber + 1}
              lastPage={pastLastPage}
              infinite={pastEvents.length > slideMinCount}
              autoplay={true}
              showOptions={!!pastEvents.length}
              fetchNewPage={(page = 1) => dispatch(loadPastEvents({ page }))}
            />
          </div>
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default HomePage
