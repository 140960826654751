import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { ReactComponent as TriangleExclaimationIcon } from 'assets/icons/triangle-exclaimation-icon.svg'
import { LayoutDialog } from 'shared'

import { useStyles } from './InfoMessageDialog.style'

const InfoMessageDialog = ({ onClose, ...dialogProps }) => {
  const classes = useStyles()

  return (
    <LayoutDialog
      {...dialogProps}
      onClose={onClose}
      hiddenHeader
      contentClassName={classes.contentDialog}
      className={classes.dialog}>
      <div className={classes.initialMessageContainer}>
        <div className={classes.alertContainer}>
          <TriangleExclaimationIcon />
          <Typography className={classes.alertParagraph}>
            Recordá que los cambios que realices, impactarán luego de presionar el botón&nbsp;
            <strong>Guardar cambios</strong> al final del formulario.
          </Typography>
        </div>
      </div>
      <Button variant='contained' color='primary' className={classes.button} onClick={onClose}>
        Aceptar
      </Button>
    </LayoutDialog>
  )
}

export default InfoMessageDialog
