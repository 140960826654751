import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { acceptAdminUser, load, rejectAdminUser } from 'state/modules/organizations'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './InvitationToManageOrganizationDialog.style'

const InvitationToManageOrganizationDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { organization, rejectAdminUserPending, acceptAdminUserPending } = useSelector(
    (state) => state.organizations
  )

  const handleRejectAdminUser = async () => {
    const rejectSuccess = await dispatch(rejectAdminUser(organization.id))

    if (rejectSuccess) {
      showSnackbarSuccess('¡La invitación fue rechaza!')
      dispatch(load(organization.id))
      onClose()
    }
  }

  const handleAcceptAdminUser = async () => {
    const acceptSuccess = await dispatch(acceptAdminUser(organization.id))

    if (acceptSuccess) {
      showSnackbarSuccess('¡La invitación fue aceptada!')
      dispatch(load(organization.id))
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      title='Invitación a colaborar en la administración de una organización'>
      <div className={classes.mainContainer}>
        <Typography variant='h6' className={classes.text}>
          {organization.createdByFullName} te ha invitado a colaborar en la administración de la
          organización {organization.name}.
        </Typography>
        <Typography variant='h6' className={classes.text}>
          Puedes aceptarla o si consideras que es un error seleccioná{' '}
          <strong>{`"Rechazar"`}</strong>
        </Typography>

        <div className={classes.buttonsContainer}>
          <Button
            color='primary'
            variant='outlined'
            className={classes.button}
            disabled={rejectAdminUserPending || acceptAdminUserPending}
            endIcon={rejectAdminUserPending && <CircularProgress color='primary' size={16} />}
            onClick={handleRejectAdminUser}>
            Rechazar
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={acceptAdminUserPending || rejectAdminUserPending}
            endIcon={acceptAdminUserPending && <CircularProgress color='primary' size={16} />}
            onClick={handleAcceptAdminUser}>
            Aceptar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default InvitationToManageOrganizationDialog
