import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    height: 30,
    [theme.breakpoints.up('lg')]: {
      width: 450
    }
  },
  textField: {
    '& input': {
      height: 9
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  searchButton: {
    height: '100%',
    boxShadow: 'none'
  },
  helperText: {
    margin: theme.spacing(2, 0)
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  select: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    color: theme.palette.primary.main,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    padding: 0,
    '& fieldset': {
      borderColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up('lg')]: {
      width: '32%'
    }
  },
  selectItem: {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  table: {
    marginTop: theme.spacing(2),
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    color: theme.palette.primary.main,
    '& th': {
      textAlign: 'left',
      fontSize: '0.7rem',
      padding: theme.spacing(0.75)
    },
    '& td': {
      textAlign: 'left',
      padding: theme.spacing(0.75),
      borderBottom: '1px solid #E6E6E6'
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#D9D9D95E'
    },
    '& table': {
      width: '100%'
    },

    '& table th': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  expandButtonCol: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  runnerImage: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'inline',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 75,
      width: 28,
      height: 28
    }
  },
  runnerName: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4.2)
    }
  },
  runnerNumberCol: {
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      display: 'table-cell'
    }
  },
  cityNameCol: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'table-cell'
    }
  },
  innerTableCell: {
    backgroundColor: '#ffff',
    padding: 0,
    '& td': {
      borderBottom: 'none'
    }
  },
  expandButton: {
    padding: 0
  },
  linkUser: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    '&.expanded': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.contrastText,
      '& a, p': {
        color: theme.palette.primary.contrastText
      }
    }
  }
}))

export default useStyles
