// Action Types
export const LOAD_UPCOMING_EVENTS_ATTEMPT = 'home/LOAD_UPCOMING_EVENTS_ATTEMPT'
export const LOAD_UPCOMING_EVENTS_SUCCESS = 'home/LOAD_UPCOMING_EVENTS_SUCCESS'
export const LOAD_UPCOMING_EVENTS_FAILURE = 'home/LOAD_UPCOMING_EVENTS_FAILURE'
export const RESET_UPCOMING_EVENTS = 'home/RESET_UPCOMING_EVENTS'

export const LOAD_PAST_EVENTS_ATTEMPT = 'home/LOAD_PAST_EVENTS_ATTEMPT'
export const LOAD_PAST_EVENTS_SUCCESS = 'home/LOAD_PAST_EVENTS_SUCCESS'
export const LOAD_PAST_EVENTS_FAILURE = 'home/LOAD_PAST_EVENTS_FAILURE'

export const RESET_PAST_EVENTS = 'home/RESET_PAST_EVENTS'
