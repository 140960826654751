import React, { useState } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { CustomizedTooltip } from 'shared'

import useStyles from './SectionBlock.style'

const SectionBlock = ({ children, title, body, activeAlertChange, hiddenBody = false }) => {
  const classes = useStyles()
  const [isTextRevealed, setIsTextRevealed] = useState(!hiddenBody || (body && body.length < 200))

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.titleContainer}>
        <Typography variant='h3' className={classes.title}>
          {title}
        </Typography>
        {activeAlertChange && (
          <CustomizedTooltip
            position='right-end'
            className={classes.tooltip}
            disabledClickAway
            buttonClassName={classes.closeButton}
            title='El cambio impactará al guardar los cambios.'>
            {({ handleTooltip }) => (
              <IconButton size='small' className={classes.alertButton} onClick={handleTooltip}>
                <ErrorIcon />
              </IconButton>
            )}
          </CustomizedTooltip>
        )}
      </div>

      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <Typography variant='body1'>{body}</Typography>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default SectionBlock
