import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ActionDialog } from 'pages/Events/InscriptionPage/components'
import { CustomizedTooltip, NumericField } from 'shared'
import { createCategory, loadCategories } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './CreateCategoryDialog.style'

const CreateCategoryDialog = ({ open, onClose, eventId, asyncError, formSetFieldValue }) => {
  const classes = useStyles()

  const values = {
    name: '',
    shortName: '',
    ageRangeMin: '',
    ageRangeMax: '',
    ageNoLimit: false,
    gender: ''
  }

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .test(
          'unique',
          'El nombre de la categoría ya existe',
          (value) => !asyncError || value !== asyncError.name
        )
        .required('El nombre de la categoría es requerido')
        .trim()
        .max(100, 'El nombre de la categoría no puede exceder de 100 caracteres'),
      shortName: Yup.string()
        .nullable()
        .required('El nombre abreviado es requerido')
        .trim()
        .max(30, 'El nombre abreviado no puede exceder de 30 caracteres'),
      ageRangeMin: Yup.number()
        .nullable()
        .when('ageNoLimit', {
          is: (ageNoLimit) => !ageNoLimit,
          then: Yup.number()
            .typeError('Ingrese un número válido')
            .required('La edad mínima es requerida')
            .positive('Ingrese un número mayor a 0')
            .integer('Ingrese un número entero')
            .min(3, 'Debe ingresar un número superior a 2')
            .max(120, 'Se superó la edad mínima permitida')
            .when('ageRangeMax', {
              is: (ageRangeMax) => !!ageRangeMax,
              then: (schema) =>
                schema.max(
                  Yup.ref('ageRangeMax'),
                  'Ingresar un valor menor o igual a la Edad máxima'
                )
            })
        }),
      ageRangeMax: Yup.number()
        .nullable()
        .when('ageNoLimit', {
          is: (ageNoLimit) => !ageNoLimit,
          then: Yup.number()
            .typeError('Ingrese un número válido')
            .required('La edad máxima es requerida')
            .integer('Ingrese un número entero')
            .min(3, 'Debe ingresar un número superior a 2')
            .max(120, 'Se superó la edad límite permitida')
            .when('ageRangeMin', {
              is: (ageRangeMin) => !!ageRangeMin,
              then: (schema) =>
                schema.min(
                  Yup.ref('ageRangeMin'),
                  'Ingresar un valor mayor o igual a la Edad mínima'
                )
            })
        }),
      gender: Yup.string().nullable().required('Debe seleccionar una opción')
    },
    ['ageRangeMin', 'ageRangeMax']
  )

  const dispatch = useDispatch()

  const handleCreateCategoryField = async (value, { setErrors }) => {
    const newCategory = {
      ...value,
      name: value.name.trim(),
      shortName: value.shortName.trim(),
      ageRangeMax: value.ageNoLimit ? 0 : Number(value.ageRangeMax),
      ageRangeMin: value.ageNoLimit ? 0 : Number(value.ageRangeMin)
    }

    const category = await dispatch(createCategory(eventId, newCategory))

    if (category) {
      showSnackbarSuccess('¡Categoría creada con éxito!')
      const data = await dispatch(loadCategories(eventId))

      formSetFieldValue('categories', data)
    } else {
      setErrors({ name: 'El nombre de la categoria ya se encuentra en uso' })
    }

    return category
  }

  return (
    <>
      <ActionDialog
        open={open}
        className={classes.dialog}
        values={values}
        onClose={onClose}
        contentClassName={classes.content}
        validationSchema={validationSchema}
        action={handleCreateCategoryField}
        title='CREAR CATEGORÍA'>
        {({ handleChange, handleBlur, errors, touched, values, isValid, isSubmitting }) => (
          <>
            <div className={classes.formGroup}>
              <Typography color='primary' variant='h6'>
                Nombre *
              </Typography>
              <TextField
                variant='outlined'
                size='small'
                name='name'
                value={values.name}
                onBlur={handleBlur}
                autoComplete='off'
                onChange={handleChange}
                placeholder='Nombre de la nueva categoría'
                helperText={errors.name}
                error={touched.name && !!errors.name}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CustomizedTooltip
                        position='bottom'
                        isTopMost
                        title='El nombre debe ser lo más completo posible, para permitir identificarla fácilmente y no debe repetirse.'>
                        {({ handleTooltip }) => (
                          <IconButton onClick={handleTooltip}>
                            <InfoIcon color='primary' />
                          </IconButton>
                        )}
                      </CustomizedTooltip>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <Typography color='primary' variant='h6'>
                Nombre abreviado *
              </Typography>
              <TextField
                variant='outlined'
                autoComplete='off'
                size='small'
                name='shortName'
                value={values.shortName}
                onChange={handleChange}
                placeholder='Nombre abreviado de la nueva categoría'
                helperText={errors.shortName}
                error={touched.shortName && !!errors.shortName}
                fullWidth
              />
            </div>

            <div className={classes.ageSection}>
              <div className={clsx(classes.formGroup, values.ageNoLimit && 'disabled')}>
                <Typography color='primary' variant='h6'>
                  Edad desde *
                </Typography>
                <NumericField
                  variant='outlined'
                  size='small'
                  name='ageRangeMin'
                  autoComplete='off'
                  onBlur={handleBlur}
                  disabled={values.ageNoLimit}
                  error={touched.ageRangeMin && !!errors.ageRangeMin}
                  placeholder='Ej: 30 años'
                  fullWidth
                  value={values.ageNoLimit ? '' : values.ageRangeMin}
                  onChange={handleChange}
                />
                {errors.ageRangeMin && (
                  <Typography color={touched.ageRangeMin ? 'error' : 'initial'} variant='caption'>
                    {errors.ageRangeMin}
                  </Typography>
                )}
              </div>
              <div className={clsx(classes.formGroup, values.ageNoLimit && 'disabled')}>
                <Typography color='primary' variant='h6'>
                  Edad hasta *
                </Typography>
                <NumericField
                  variant='outlined'
                  size='small'
                  onBlur={handleBlur}
                  disabled={values.ageNoLimit}
                  autoComplete='off'
                  placeholder='Ej: 40 años'
                  name='ageRangeMax'
                  fullWidth
                  error={touched.ageRangeMax && !!errors.ageRangeMax}
                  value={values.ageNoLimit ? '' : values.ageRangeMax}
                  onChange={handleChange}
                />
                {errors.ageRangeMax && (
                  <Typography color={touched.ageRangeMax ? 'error' : 'initial'} variant='caption'>
                    {errors.ageRangeMax}
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.ageNoLimit}
                    name='ageNoLimit'
                    onChange={handleChange}
                  />
                }
                label='Sin límite de edad'
              />
            </div>
            <div className={classes.formGroup}>
              <Typography variant='h6' color='primary'>
                Género *
              </Typography>
              <RadioGroup
                className={classes.radioButtonContainer}
                aria-label='gender'
                name='gender'
                value={values.gender}
                onChange={handleChange}>
                <FormControlLabel
                  value='Female'
                  control={
                    <Radio
                      color='primary'
                      classes={{
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label='Femenino'
                />
                <FormControlLabel
                  value='Male'
                  control={
                    <Radio
                      color='primary'
                      classes={{
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label='Masculino'
                />
                <FormControlLabel
                  value='NonBinary'
                  control={
                    <Radio
                      color='primary'
                      classes={{
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label='No binario'
                />
                <FormControlLabel
                  value='Other'
                  control={
                    <Radio
                      color='primary'
                      classes={{
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label='Libre'
                />
              </RadioGroup>
              {errors.gender && (
                <Typography color={touched.gender ? 'error' : 'initial'} variant='caption'>
                  {errors.gender}
                </Typography>
              )}
            </div>
            <Button
              disabled={!isValid || isSubmitting}
              endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
              className={classes.actionButton}
              color='primary'
              type='submit'
              variant='contained'>
              Crear
            </Button>
          </>
        )}
      </ActionDialog>
    </>
  )
}

export default CreateCategoryDialog
