import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(0, 5),
    textAlign: 'left'
  },
  eventContainer: {
    width: '90%',
    margin: '0 auto',
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 90,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down('md')]: {
      '& #slick-arrow': {
        position: 'static !important',
        cursor: 'pointer',
        width: 17,
        height: 32,
        marginTop: 0
      }
    },

    [theme.breakpoints.up('md')]: {
      padding: 0,
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  showAllLink: {
    marginTop: 30,
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      paddingRight: theme.spacing(2)
    }
  },
  listContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& #feature-image-container': {
        height: '44vw'
      }
    },

    '& > div': {
      maxHeight: 471
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  loadingContainer: {
    width: '90%',
    margin: '30px auto'
  },
  noData: {
    textAlign: 'center'
  }
}))
