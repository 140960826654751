import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),

    [theme.breakpoints.up('lg')]: {
      '&.alt': {
        backgroundColor: '#969696 !important'
      },
      display: 'flex'
    }
  },
  featureImageContainerMobile: {
    maxHeight: 620,
    height: '48vw',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  featureImageContainer: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      minWidth: 827,
      width: '60%',
      height: '29vw',
      maxHeight: 537,
      minHeight: 403,
      flexShrink: 0,
      maxWidth: 1102
    }
  },
  featureImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'opacity ease-in-out 400ms',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%'
  },
  panel: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  eventInfoContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',

    '&.alt': {
      backgroundColor: '#969696 !important'
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      background: 'transparent'
    }
  },
  date: {
    fontWeight: 500,
    fontSize: '1.2rem',
    padding: theme.spacing(2),
    flexShrink: 0,
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6)
    }
  },

  titleContainer: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    padding: theme.spacing(3, 2),
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 6)
    }
  },
  title: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '1rem',
    textAlign: 'center',

    '&.results': {
      fontWeight: 600,
      fontSize: '1.375rem'
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3rem',
      textAlign: 'left'
    }
  },
  eventButtonBlock: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      padding: theme.spacing(0, 6),
      marginBottom: 0
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },

  enableInscriptionButton: {
    borderRadius: 10,
    padding: theme.spacing(1.5, 4),
    width: 234,
    height: 48,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },

  downloadEventResultsButton: {
    borderRadius: 10,
    width: 240,
    height: 48,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  inscriptionStateButton: {
    borderRadius: 10,
    padding: theme.spacing(1.5),
    height: 48,
    width: 234,
    '& span': {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  resultButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing()
  },
  downloadResultsLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '.9rem',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      width: 180
    }
  },
  inscriptionLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '1rem',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    marginTop: -theme.spacing(2)
  },
  actionBarButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 50,
    alignItems: 'center',

    '&.hidden': {
      visibility: 'hidden'
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      marginRight: theme.spacing(3)
    }
  },
  actionButton: {
    border: 'none',
    '&:hover': {
      border: 'none'
    },
    '&:disabled': {
      border: 'none',
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto'
    }
  },
  actionButtonLabel: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    '& em': {
      fontSize: '1.25em',
      fontStyle: 'normal',
      marginLeft: theme.spacing(1)
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& span': {
        marginLeft: theme.spacing(1)
      }
    }
  },
  shareButton: {
    color: theme.palette.primary.contrastText
  },
  kayak: {
    '&.size-2': {
      backgroundPositionX: '38%'
    },
    '&.size-3': {
      backgroundPositionX: '30%'
    }
  },
  running: {
    '&.size-2': {
      backgroundPositionX: '27%'
    },
    '&.size-3': {
      backgroundPositionX: '32%'
    }
  },
  mountainBike: {
    '&.size-2': {
      backgroundPositionX: '46%'
    },
    '&.size-3': {
      backgroundPositionX: '60%'
    }
  },
  natacion: {
    '&.size-2': {
      backgroundPositionX: '55%'
    },
    '&.size-3': {
      backgroundPositionX: '70%'
    }
  },
  biking: {
    '&.size-2': {
      backgroundPositionX: '1%'
    },
    '&.size-3': {
      backgroundPositionX: '15%'
    }
  },
  fbi: {
    '&.size-2': {
      backgroundPositionX: '52%'
    }
  },
  automovilismo: {
    '&.size-2': {
      backgroundPositionX: '42%'
    },
    '&.size-3': {
      backgroundPositionX: '26%'
    }
  },
  motocross: {
    '&.size-2': {
      backgroundPositionX: '97%'
    },
    '&.size-3': {
      backgroundPositionX: '91%'
    }
  },
  imageInfoContainer: {
    backgroundColor: theme.utils.rgba('#D9D9D9B2', 0.71),
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      paddingTop: theme.spacing(6)
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    [theme.breakpoints.up('lg')]: {
      gap: '.7vw'
    }
  },
  checkIcon: {
    color: theme.palette.primary.contrastText
  },
  infoTitle: {
    fontWeight: 600,
    fontSize: '3.15vw',
    marginBottom: 6,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3vw',
      marginBottom: 0
    }
  },
  infoText: {
    fontWeight: 600,
    width: '80%',
    fontSize: '2.89vw',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.1vw',
      width: '100%'
    }
  },
  endText: {
    marginTop: 6
  }
}))
