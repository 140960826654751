import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { deleteDistance, loadDistances } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { ActionDialog } from '../ActionDialog'
import { DeleteItemNotifyDialog } from '../DeleteItemNotifyDialog'

import { useStyles } from './DeleteDistanceDialog.style'

const DELETE_ITEM_NOTIFY_DIALOG_STATES = {
  DELETE_DISTANCE_CONFIRM: 'deleteDistanceConfirm',
  DELETE_DISTANCE_NOT_ALLOWED: 'deleteDistanceNotAllowed'
}

const DeleteDistanceDialog = ({ selectedDistance, eventId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { values: formState, setValues } = useFormikContext()

  const [openDeleteDistanceDialog, setOpenDeleteDistanceDialog] = useState(false)

  const [deleteDistancePending, setDeleteDistancePending] = useState(false)
  const [openDeleteNotifyDialog, setOpenDeleteNotifyDialog] = useState(false)
  const [deleteDistanceNotifyProps, setDeleteDistanceNotifyProps] = useState({
    state: DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_DISTANCE_NOT_ALLOWED
  })

  const distance = {
    name: '',
    distance: '',
    quota: '',
    ...selectedDistance.distance
  }

  const handleDeleteDistance = async (value) => {
    setDeleteDistancePending(true)

    const deleteSuccess = await dispatch(deleteDistance(eventId, value.id))

    if (deleteSuccess) {
      const data = await dispatch(loadDistances(eventId))

      setValues((values) => ({
        ...values,
        items: values.items.filter((item) => item.eventDistance.id !== value.id),
        ticketTypes: values.ticketTypes.map((x) => {
          const items = x.items.filter((i) => i.inscriptionFormItem.eventDistanceId !== value.id)
          return {
            ...x,
            hasChanges:
              x.items.length !== items.length ||
              x.items.some(
                (x) =>
                  !items.some(
                    (i) =>
                      i.inscriptionFormItem.eventDistanceId ===
                        x.inscriptionFormItem.eventDistanceId &&
                      i.inscriptionFormItem.eventCategoryId ===
                        x.inscriptionFormItem.eventCategoryId
                  )
              ),
            items
          }
        }),
        distances: data
      }))

      showSnackbarSuccess('¡Distancia eliminada con éxito!')
      return true
    }
  }

  const handleOpenDeleteDistanceDialog = () => {
    const hasBindTicket = formState.ticketTypes.some((x) =>
      x.items.some((i) => i.inscriptionFormItem.eventDistanceId === distance.id)
    )

    if (!hasBindTicket) return setOpenDeleteDistanceDialog(true)

    const stateConditional = formState.ticketTypes.some(
      (x) =>
        x.items.filter(
          (i) => !!i.inscriptionFormItem && i.inscriptionFormItem.eventDistanceId !== distance.id
        ).length < 1
    )

    setDeleteDistanceNotifyProps({
      ...deleteDistanceNotifyProps,
      distance,
      state: stateConditional
        ? DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_DISTANCE_CONFIRM
        : DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_DISTANCE_NOT_ALLOWED,

      ...(stateConditional
        ? {
            onAccept: () => setOpenDeleteNotifyDialog(false)
          }
        : {
            onClose: () => setOpenDeleteNotifyDialog(false)
          })
    })

    setOpenDeleteNotifyDialog(true)
  }

  return (
    <>
      <IconButton
        color='primary'
        onClick={handleOpenDeleteDistanceDialog}
        title='Eliminar distancia'>
        <DeleteOutlineIcon />
      </IconButton>
      <ActionDialog
        open={openDeleteDistanceDialog}
        className={classes.dialog}
        values={distance}
        action={handleDeleteDistance}
        formClassName={classes.formContainer}
        title='ELIMINAR DISTANCIA'>
        {({ values, isValid, isSubmitting }) => (
          <>
            <div className={classes.deleteDialogBody}>
              <Typography variant='h6' align='center' color='primary' className={classes.subtitle}>
                ¿Está seguro que desea eliminar la distancia {values.name}?
              </Typography>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.actionButton}
                  color='primary'
                  variant='contained'
                  onClick={() => setOpenDeleteDistanceDialog(false)}>
                  Cancelar
                </Button>
                <Button
                  className={classes.actionButton}
                  color='primary'
                  variant='contained'
                  endIcon={deleteDistancePending && <CircularProgress size={16} color='primary' />}
                  type='submit'
                  disabled={!isValid || isSubmitting}>
                  Eliminar
                </Button>
              </div>
            </div>
          </>
        )}
      </ActionDialog>
      <DeleteItemNotifyDialog
        open={openDeleteNotifyDialog}
        onSubmit={() => handleDeleteDistance(distance)}
        {...deleteDistanceNotifyProps}
      />
    </>
  )
}

export default DeleteDistanceDialog
