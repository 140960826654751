import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  profileImageButton: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block'
  },
  editLayer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .75)',
    opacity: 0,
    transition: 'opacity ease-in-out 400ms',
    '& > div': {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }
    },
    '&.hover': {
      opacity: 1
    }
  },

  cameraIcon: {
    width: 64,
    height: 64
  },
  progressSuperContainer: {
    position: 'relative'
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .75)'
  },
  editButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  container: {
    position: 'relative',
    transition: 'opacity ease-in-out 400ms'
  },
  editMiniButton: {
    position: 'absolute',
    bottom: 20,
    padding: theme.spacing(1.5),
    borderRadius: '50%',
    width: 60,
    height: 60,
    minWidth: 60,
    zIndex: 1,
    right: 15,
    '& span': {
      fontSize: '1rem',
      fontWeight: 500
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 39,
      height: 48,
      width: 'auto',
      borderRadius: 10,
      padding: theme.spacing(1.5, 3.5)
    }
  },
  addPhotoIcon: {
    width: 39,
    height: 39,
    [theme.breakpoints.up('md')]: {
      width: 30,
      height: 30,
      marginLeft: theme.spacing(2)
    }
  },
  cropper: {
    height: '100%',
    width: '100%',
    '& > div': {
      width: '100%'
    }
  },
  optionsEditContainer: {
    backgroundColor: theme.utils.rgba('#E3D9D9B8', 0.72),
    width: '100%',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    justifyContent: 'flex-end'
  },
  buttonContainer: {
    display: 'flex',
    width: 300,
    justifyContent: 'space-evenly',
    marginRight: theme.spacing(2),
    '& button': {
      padding: theme.spacing(1, 4)
    }
  },
  cancelButton: {
    backgroundColor: '#E3D9D9',
    border: 'none',
    fontWeight: 600,
    '&:hover': {
      border: 'none',
      backgroundColor: theme.utils.rgba('#E3D9D9', 0.8)
    }
  }
}))
