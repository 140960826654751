import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: '#ffff !important'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',

    '&.margin': {
      marginTop: theme.spacing(9)
    }
  },
  downloadEventResultsButton: {
    borderRadius: 10,
    width: 240,
    height: 48,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inscriptionStateButton: {
    borderRadius: 10,
    padding: theme.spacing(1.5),
    height: 48,
    width: 234,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  resultsButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  title: {
    border: 'none',
    textAlign: 'center',
    fontSize: '1.25rem'
  }
}))
