import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { SectionBlock } from 'pages/Events/InscriptionPage/components'

import { OfflinePaymentBlock } from '../OfflinePaymentBlock'
import { OnlinePaymentBlock } from '../OnlinePaymentBlock'

import { useStyles } from './PaymentMethodsSection.style'

const PAYMENT_STATES = {
  PAYMENT_DISABLED: 'paymentDisabled',
  PAYMENT_PENDING: 'paymentPending'
}

const PaymentMethodsSection = () => {
  const classes = useStyles()

  const { categories, distances } = useSelector((state) => state.events)

  const { values: formState, initialValues } = useFormikContext()

  const ticketOnlyMercadoPago = () =>
    formState.ticketTypes
      .map(
        (e) =>
          e.mercadoPagoEnabled === true &&
          e.paymentLocationsEnabled === false &&
          e.bankAccountsEnabled === false &&
          !e.paymentUrl
      )
      .includes(true)

  const paymentEnabled = !!categories.length && !!distances.length && !formState.isFree

  return (
    <SectionBlock
      title='3 Medios de pago*'
      activeAlertChange={
        formState.formPublished &&
        Number(initialValues.paymentConfirmationPeriod) !==
          Number(formState.paymentConfirmationPeriod)
      }>
      <div className={classes.headerContainer}>
        <Typography className={classes.subtitle}>
          Seleccioná y configurá los medios de pago para tu evento:
        </Typography>
      </div>

      <OnlinePaymentBlock
        onlinePaymentEnabled={paymentEnabled}
        paymentStates={PAYMENT_STATES}
        ticketOnlyMercadoPago={ticketOnlyMercadoPago}
      />

      <OfflinePaymentBlock offlinePaymentEnabled={paymentEnabled} paymentStates={PAYMENT_STATES} />
    </SectionBlock>
  )
}

export default PaymentMethodsSection
