import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import { LayoutDialog } from 'shared'
import { downloadResultsTemplate } from 'state/modules/events'
import { normalizeUrl } from 'utils/functions'
import * as Yup from 'yup'

import { useStyles } from './UploadResultsDialog.style'

const UploadResultsDialog = ({ onClose, onUploadResults, afterEvent, onSave, ...dialogProps }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputRef = useRef()
  const [insertExternalResultsUrlMode, setInsertExternalResultsUrlMode] = useState(false)
  const [partialResults, setPartialResults] = useState(false)

  const { uploadResultsPending } = useSelector((state) => state.events)

  const validation = Yup.object().shape({
    externalResultsUrl: Yup.string()
      .required('Debe ingresar la url para continuar')
      .transform((value) =>
        !!value && !value.includes('http://') && !value.includes('https://')
          ? `http://${value}`
          : value
      )
      .url('La url ingresada no es valida')
  })

  const values = {
    externalResultsUrl: ''
  }

  const onSubmit = async (values) => {
    const externalResultsUrl = normalizeUrl(values.externalResultsUrl)
    await onSave({ value: { externalResultsUrl, hasPartialResults: partialResults } })
    onClose()
  }

  const handleOnClose = () => {
    onClose()
    setInsertExternalResultsUrlMode(false)
  }

  const handleDownloadResultsTemplate = (e) => {
    e.preventDefault()
    dispatch(downloadResultsTemplate())
  }

  return (
    <LayoutDialog {...dialogProps} onClose={handleOnClose}>
      {!insertExternalResultsUrlMode ? (
        <>
          <Typography>
            Puedes cargar un archivo en formato <strong>excel / pdf</strong> ó añadir enlace
            externo.
          </Typography>
          <br />
          <Typography>
            En caso de cargar un archivo excel deberás respetar el formato: <br />
            <Link to='#' onClick={handleDownloadResultsTemplate} className={classes.link}>
              Planilla Modelo de resultados
            </Link>
            &nbsp; para que vincule con el historial deportivo (esto permite que el deportista pueda
            ver los resultados en su perfil).
          </Typography>
          <br />
          <Typography>
            Si utilizas&nbsp;
            <Link className={classes.link} to={ROUTES.LANDING.TIMING}>
              Sportmetric Crono
            </Link>
            &nbsp; tu archivo excel ya cuenta con el formato indicado para subirlo.
          </Typography>
          <div className={classes.actionsContainer}>
            <Button
              color='primary'
              variant='contained'
              endIcon={uploadResultsPending && <CircularProgress size={16} color='primary' />}
              className={classes.button}
              disabled={uploadResultsPending}
              onClick={() => inputRef.current.click()}>
              Cargar Archivo
            </Button>
            <input
              type='file'
              name='uploadResults'
              ref={inputRef}
              onChange={onUploadResults(partialResults)}
              accept='.pdf, .xlsx'
              style={{ display: 'none' }}
            />
            <Button
              color='primary'
              variant='contained'
              disabled={uploadResultsPending}
              className={classes.button}
              onClick={() => setInsertExternalResultsUrlMode(true)}>
              Añadir enlace
            </Button>
          </div>
          {!afterEvent && (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={partialResults}
                  className={classes.checkbox}
                  onChange={(e) => setPartialResults(e.target.checked)}
                  name='partialResults'
                />
              }
              label={
                <Typography variant='h6' className={classes.checkMiniLabel}>
                  Tildar aquí si los resultados cargados son parciales.
                </Typography>
              }
            />
          )}
        </>
      ) : (
        <div className={classes.linkExternalContainer}>
          <Formik initialValues={values} validationSchema={validation} onSubmit={onSubmit}>
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
              isValid
            }) => (
              <form onSubmit={handleSubmit} className={classes.linkForm}>
                <div className={classes.linkBodyContainer}>
                  <Typography>Pegue aquí su link de resultados</Typography>
                  <TextField
                    color='primary'
                    variant='outlined'
                    type='text'
                    autoComplete='off'
                    fullWidth
                    name='externalResultsUrl'
                    autoFocus
                    onBlur={handleBlur}
                    error={!!errors.externalResultsUrl && touched.externalResultsUrl}
                    helperText={errors.externalResultsUrl}
                    onChange={handleChange}
                    value={values.externalResultsUrl}
                    className={classes.textField}
                  />
                  <Typography>
                    Los usuarios podrán acceder a él desde el perfil del evento
                  </Typography>
                </div>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
                  disabled={isSubmitting || !isValid}
                  className={classes.saveUrlButton}>
                  Guardar
                </Button>
              </form>
            )}
          </Formik>
        </div>
      )}
    </LayoutDialog>
  )
}

export default UploadResultsDialog
