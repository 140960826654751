import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 40,
    padding: '6px',
    '&:disabled': {
      color: theme.palette.primary.contrastText
    }
  }
}))
