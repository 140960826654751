import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { ConfirmDialog } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { createCustomField, loadCustomFields } from 'state/modules/organizations'
import { showSnackbarSuccess } from 'utils/snackbar'

import { ActionCustomFieldDialog } from '../ActionCustomFieldDialog'
import { CustomFieldDialog } from '../CustomFieldDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SectionCustomField.style'

const SectionCustomField = ({ organizationId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setValues, values, setFieldValue, initialValues } = useFormikContext()

  const { customFields } = useSelector((state) => state.organizations)

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false)

  const items = [...customFields, { id: 'button', name: 'Nuevo campo personalizado' }]

  useEffect(() => {
    dispatch(loadCustomFields(organizationId))
  }, [dispatch, organizationId])

  const handleOnChangeSelect = (e, field) => {
    if (e.value !== 'button') {
      e.label = items.find((item) => item.id === e.value)?.name || e.label
      setSelectedOption(e)
    } else {
      if (field === 'selectedCustomField') {
        if (customFields.length < 10) {
          setOpenDialog(true)
        } else {
          setOpenCustomFieldDialog(true)
        }
      }
    }
  }

  const handleAddOption = (state, setFieldValue, field) => (e) => {
    e.preventDefault()
    const newOption = items.find((item) => item.id === selectedOption.value)

    setFieldValue(field, [
      ...state[field],
      {
        eventCustomField: newOption,
        eventCustomFieldId: newOption.id,
        itemNumber: state[field].length + 1
      }
    ])
    setSelectedOption('')
  }

  const handleCreateCustomField = async (value) => {
    const newCustomField = {
      ...value,
      isRequired: value.isRequired === 'Si',
      options: (value.type === 'List' && value.options?.filter((e) => !!e.name?.trim())) || [],
      allowMultiselect: false
    }
    const field = await dispatch(createCustomField(organizationId, { ...newCustomField }))

    await dispatch(loadCustomFields(organizationId))

    return field
  }

  const handleLabel = (type) => {
    switch (type) {
      case 'Text':
        return 'Texto'
      case 'Number':
        return 'Número'
      case 'Boolean':
        return 'Si/No'
      case 'List':
        return 'Lista desplegable'
      case 'File':
        return 'Archivo adjunto'
      default:
        return 'Texto'
    }
  }

  const transformOption = (item) => ({
    value: item.id,
    label: (
      <div className={classes.option}>
        <div className={clsx(classes.optionName, !!item.type && 'withType')} title={item.name}>
          {item.name}
        </div>
        <div title={handleLabel(item.type)}>{item.type ? handleLabel(item.type) : ''}</div>
      </div>
    )
  })

  const filterOptions = async (inputValue) =>
    [
      ...customFields.filter((i) => i.name.toLowerCase().includes(inputValue.toLowerCase())),
      { id: 'button', name: 'Nuevo campo personalizado' }
    ].map((item) => transformOption(item))

  const onCreateAction =
    (setValues) =>
    async (value, { setErrors }) => {
      const data = await handleCreateCustomField(value)
      if (data) {
        showSnackbarSuccess('¡Campo personalizado creado con éxito!')

        setValues((values) => ({
          ...values,
          customFields: [
            ...values.customFields,
            {
              eventCustomField: data,
              eventCustomFieldId: data.id,
              itemNumber: values.customFields.length + 1
            }
          ]
        }))

        return true
      } else {
        setErrors({ name: 'El nombre del campo ya existe' })
      }
    }

  return (
    <SectionBlock
      title='Campos personalizados'
      body='Aquí podrás crear y editar cada solicitud de información que necesitás que los deportistas completen al momento de la inscripción.'
      activeAlertChange={
        values.formPublished &&
        (initialValues.customFields.length !== values.customFields.length ||
          !initialValues.customFields.every((x) =>
            values.customFields.some((c) => x.eventCustomFieldId === c.eventCustomFieldId)
          ))
      }>
      <form className={classes.comboxsContainer}>
        <StyledSelect
          defaultOptions={items.map((item) => transformOption(item))}
          loadOptions={filterOptions}
          name='selectCustomField'
          colorOptions='secondary'
          value={selectedOption}
          placeholder='Agregar campo personalizado'
          filterOption={(option) =>
            !values.customFields.find((e) => e.eventCustomFieldId === option.value)
          }
          onChange={(e) => handleOnChangeSelect(e, 'selectedCustomField')}
          className={classes.select}
        />
        <Button
          color='primary'
          variant='contained'
          disabled={!selectedOption}
          type='submit'
          className={classes.addButton}
          onClick={handleAddOption(values, setFieldValue, 'customFields')}>
          Agregar
        </Button>
        <ConfirmDialog
          openDialog={openCustomFieldDialog}
          title='¡Límite de campos personalizados alcanzado!'
          type='info'
          titleClassName={classes.dialogTitle}
          message='Para crear un nuevo campo personalizado debe eliminar alguno de los ya existentes.'
          setOpen={setOpenCustomFieldDialog}
          onAction={() => setOpenCustomFieldDialog(false)}
        />
        <ActionCustomFieldDialog
          action={onCreateAction(setValues)}
          list={customFields}
          actionName='Crear'
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          title='Crear campo personalizado'
        />
      </form>
      <Typography className={classes.messageText}>
        En caso de necesitar crear un nuevo campo presioná en crear y comenzá a definirlo.
        <br />
        Recordá que solo podés crear hasta 10 campos personalizados.
      </Typography>
      <CustomFieldDialog
        customFields={customFields}
        organizationId={organizationId}
        values={values}
        onCreate={handleCreateCustomField}
        setFieldValue={setFieldValue}
      />
    </SectionBlock>
  )
}

export default SectionCustomField
