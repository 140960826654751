import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import {
  ConfirmDialog,
  CustomizedTooltip,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  SuccessInscriptionDialog
} from 'shared'
import {
  deleteResults,
  load,
  loadEventInscription,
  loadReset,
  resetValue,
  updateEvent
} from 'state/modules/events'
import { useQuery } from 'utils/hooks'
import { showSnackbarError } from 'utils/snackbar'

import {
  CircuitSection,
  ContactSection,
  DescriptionSection,
  DisciplineSection,
  EventCreatedDialog,
  EventDateSection,
  EventDrawer,
  EventPublishedDialog,
  HeaderProfile,
  InscriptionUrlSection,
  LocationSection,
  MercadoPagoCancelledPaymentDialog,
  OrganizationsSection,
  PlaceHolderLoader,
  ResultsSection,
  RulesSection,
  ScheduleSection,
  SuccessCreateMercadoPagoAuthorizationDialog
} from './components'
import useStyles from './ProfilePage.style'

const EVENT_STATES = {
  SUSPENDED: 'suspended',
  RESULTS: 'results',
  ADMIN_MODE: 'adminMode'
}

const SALE_ORDER_STATE = {
  PAID: 'Paid',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled'
}

const ProfilePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const query = useQuery()
  const { slugUrlOrId } = useParams()

  const mercadoPagoCodeParam = query.get('mercadoPagoCode')
  const saleOrderStateParam = query.get('saleOrderState')
  const eventInscriptionIdParam = query.get('eventInscription')
  const previewViewParam = query.get('previewView')
  const eventCreatedParam = query.get('eventCreated')

  const [openDialogResults, setOpenDialogResults] = useState(false)

  const [openSuccessCreateMercadoPagoAuthorization, setOpenSuccessCreateMercadoPagoAuthorization] =
    useState(false)
  const [
    openSuccessCreateInscriptionForMercadoPago,
    setOpenSuccessCreateInscriptionForMercadoPago
  ] = useState(false)

  const [openMercadoPagoCancelledPaymentDialog, setOpenMercadoPagoCancelledPaymentDialog] =
    useState(false)

  const [openDrawer, setOpenDrawer] = useState(true)
  const [openEventCreatedDialog, setOpenEventCreatedDialog] = useState(false)
  const [openEventPublishedDialog, setOpenEventPublishedDialog] = useState(false)

  const {
    event,
    pending,
    error: asyncError,
    eventInscriptionsForCurrentUserError
  } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const activeLoading = (!pending && !event && !asyncError) || pending

  const activeMessageError =
    !event &&
    asyncError &&
    asyncError.response &&
    asyncError.response.data &&
    asyncError.response.data.status === 404

  useEffect(() => {
    if (
      !!mercadoPagoCodeParam &&
      !!event &&
      event.mercadoPagoEnabled &&
      mercadoPagoCodeParam === event.mercadoPagoCode
    ) {
      setOpenSuccessCreateMercadoPagoAuthorization(true)
    } else if (!!saleOrderStateParam && !!event && event.mercadoPagoEnabled) {
      if (saleOrderStateParam === SALE_ORDER_STATE.PAID) {
        dispatch(loadEventInscription(eventInscriptionIdParam))
        setOpenSuccessCreateInscriptionForMercadoPago(true)
      } else if (saleOrderStateParam === SALE_ORDER_STATE.CANCELLED)
        setOpenMercadoPagoCancelledPaymentDialog(true)
    }
  }, [event])

  useEffect(() => {
    if (eventCreatedParam && event && !event.isPublished) setOpenEventCreatedDialog(true)
  }, [event, eventCreatedParam])

  useEffect(() => {
    dispatch(load(slugUrlOrId))

    return () => dispatch(loadReset())
  }, [dispatch, slugUrlOrId, user])

  useEffect(() => {
    showMessage(asyncError, event)

    return () => dispatch(resetValue('error'))
  }, [asyncError, event])

  useEffect(() => {
    if (eventInscriptionsForCurrentUserError)
      showSnackbarError(eventInscriptionsForCurrentUserError)

    return () => dispatch(resetValue('eventInscriptionsForCurrentUserError'))
  }, [eventInscriptionsForCurrentUserError])

  const renderLoading = () => (
    <div className={classes.loaderContainer}>
      <PlaceHolderLoader />
    </div>
  )

  const renderMessageNoFound = () => (
    <div className={classes.messageContainer}>
      <Typography color='primary' variant='h5'>
        Ocurrió un error al cargar el evento
      </Typography>
      <Typography color='primary'>Verifique el enlace del Evento.</Typography>
    </div>
  )

  const renderMessageAccessBlocked = () => {
    return (
      <div className={classes.messageContainer}>
        <Typography color='primary' variant='h5'>
          No tienes acceso a este evento
        </Typography>
      </div>
    )
  }

  const renderProfilePage = () => {
    if (activeMessageError) return renderMessageNoFound()

    const accessBlocked =
      !event.isPublished &&
      !event.organizations.some(
        (x) =>
          x.organization.loggedUserMetadata?.canEdit &&
          (x.acceptedAt || (!x.acceptedAt && !x.rejectedAt))
      )

    if (accessBlocked) return renderMessageAccessBlocked()

    return renderEvent()
  }

  const handleSave = async ({ value }) => {
    const eventUpdate = { ...event, ...value }

    await dispatch(updateEvent(eventUpdate))
    dispatch(load(slugUrlOrId))
  }

  const showMessage = (asyncError, event) => {
    if (asyncError && event) showSnackbarError(asyncError)
  }

  const tryDeleteResults = async () => {
    if (event.externalResultsUrl) {
      handleSave({ value: { externalResultsUrl: '' } })
      return
    }

    await dispatch(deleteResults(event.id))
    await dispatch(load(event.id))
  }

  const renderEvent = () => {
    const {
      to,
      externalInscriptionUrl,
      isSuspended,
      isPublished,
      loggedUserMetadata,
      hasResults,
      from,
      hasResultsSheetFile,
      organization: eventOwner
    } = event

    const { canEdit } = loggedUserMetadata
    const toLocal = moment(to)
    const fromLocal = moment(from)

    const afterEvent = toLocal < moment()
    const inProgressEvent = fromLocal.isBefore(moment())

    const canEditAdmin = canEdit && !previewViewParam

    const activeEditMode = canEditAdmin && !inProgressEvent
    const hasEventResults = hasResults && hasResultsSheetFile
    const isEventOwner = eventOwner.loggedUserMetadata?.canEdit
    const canEditOrganization = isEventOwner && activeEditMode

    const handleSuccessCreateMercadoPagoAuthorization = () => {
      setOpenSuccessCreateMercadoPagoAuthorization(false)
      history.replace(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)
    }

    const handleSuccessCreateInscriptionForMercadoPago = () => {
      setOpenSuccessCreateInscriptionForMercadoPago(false)
      history.replace(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)
    }

    const handleCloseMercadoPagoCancelledPaymentDialog = () => {
      setOpenMercadoPagoCancelledPaymentDialog(false)
      history.replace(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)
    }

    return (
      <div className={classes.container}>
        <HeaderProfile
          handleSave={handleSave}
          canEdit={canEditAdmin}
          inProgressEvent={inProgressEvent}
        />

        <div className={classes.containerBody}>
          <div className={clsx(classes.mainContainer, (hasResults || isSuspended) && 'row')}>
            {hasResults && (
              <div
                className={clsx(
                  classes.resultsContainer,
                  hasEventResults && EVENT_STATES.RESULTS,
                  isSuspended && EVENT_STATES.SUSPENDED
                )}>
                <div className={classes.resultsHeaderContainer}>
                  {!canEditAdmin && (
                    <div className={clsx(classes.titleContainerMobile, EVENT_STATES.RESULTS)}>
                      <Typography variant='h2' className={classes.title} color='primary'>
                        {event.name} - Evento {afterEvent ? 'finalizado' : 'en curso'}
                      </Typography>
                    </div>
                  )}
                </div>
                {canEditAdmin && (
                  <ConfirmDialog
                    openDialog={openDialogResults}
                    message='Esta acción eliminará los resultados del evento.
                                    ¿Esta seguro que desea continuar?'
                    setOpen={setOpenDialogResults}
                    onAction={tryDeleteResults}
                    type='delete'
                  />
                )}

                {(hasEventResults || (isMobile && !canEditAdmin)) && (
                  <ResultsSection
                    hasEventResults={hasEventResults}
                    event={event}
                    activeDrawer={isMobile && hasEventResults}
                    afterEvent={afterEvent}
                    canEdit={canEditAdmin}
                  />
                )}
              </div>
            )}
            <EventDrawer
              open={openDrawer}
              drawerClassName={clsx(
                classes.rowContainer,
                hasEventResults && EVENT_STATES.RESULTS,
                isSuspended && EVENT_STATES.SUSPENDED
              )}
              className={clsx(
                classes.rowContainer,
                hasEventResults && EVENT_STATES.RESULTS,
                isSuspended && EVENT_STATES.SUSPENDED,
                isMobile && canEditAdmin && EVENT_STATES.ADMIN_MODE
              )}
              setOpen={setOpenDrawer}
              activeDrawer={hasEventResults && !isMobile}>
              {hasEventResults && !isMobile && (
                <Typography className={classes.resultsTitle}>
                  Evento {afterEvent ? 'finalizado' : 'en curso'}
                </Typography>
              )}

              <div
                className={clsx(
                  classes.leftColumn,
                  isSuspended && EVENT_STATES.SUSPENDED,
                  hasEventResults && EVENT_STATES.RESULTS
                )}>
                {canEditAdmin && <EventDateSection event={event} onSave={handleSave} />}

                <DescriptionSection
                  event={event}
                  canEdit={canEditAdmin}
                  onSave={handleSave}
                  isMobile={isMobile}
                  afterEvent={afterEvent}
                />

                <LocationSection event={event} canEdit={activeEditMode} onSave={handleSave} />

                {externalInscriptionUrl && canEditAdmin && (
                  <InscriptionUrlSection
                    event={event}
                    canEdit={activeEditMode}
                    onSave={handleSave}
                  />
                )}

                <RulesSection event={event} canEdit={activeEditMode} onSave={handleSave} />

                <CircuitSection event={event} canEdit={activeEditMode} onSave={handleSave} />

                <ScheduleSection
                  event={event}
                  canEdit={activeEditMode}
                  onSave={handleSave}
                  adminMode={canEditAdmin}
                />
              </div>
              <div
                className={clsx(
                  classes.rightColumn,
                  hasEventResults && EVENT_STATES.RESULTS,
                  isSuspended && EVENT_STATES.SUSPENDED
                )}>
                {canEditAdmin && !hasEventResults && (
                  <div className={classes.asideContainer}>
                    {!isPublished ? (
                      <CustomizedTooltip
                        position='top-start'
                        title='Te recomendamos completar todos los campos que aporten información valiosa al usuario antes de publicar el evento.'
                        className={classes.tooltip}
                        arrowClassName={classes.arrowTooltip}>
                        {({ handleTooltip, handleTooltipClose }) => (
                          <Button
                            color='primary'
                            variant='outlined'
                            className={classes.publishButton}
                            onClick={() => setOpenEventPublishedDialog(true)}
                            endIcon={
                              <InfoIcon
                                color='primary'
                                className={classes.infoIcon}
                                onMouseOut={handleTooltipClose}
                                onMouseOver={handleTooltip}
                              />
                            }>
                            Publicar evento
                          </Button>
                        )}
                      </CustomizedTooltip>
                    ) : (
                      <Typography
                        color='primary'
                        variant='h5'
                        className={classes.publishedEventTitle}>
                        Evento publicado
                      </Typography>
                    )}

                    <Button
                      color='primary'
                      variant='contained'
                      disabled={inProgressEvent}
                      className={classes.editButton}
                      component={Link}
                      to={`${ROUTES.EVENTS.EDIT}/${slugUrlOrId}`}>
                      Editar evento
                    </Button>

                    <Link
                      to={`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}?previewView=true`}
                      color='primary'
                      target='_blank'
                      className={classes.previewViewLink}>
                      Vista previa del evento
                    </Link>
                  </div>
                )}

                {!canEditAdmin && (
                  <Typography color='primary' variant='h5' className={classes.organizationTitle}>
                    Datos de la organización
                  </Typography>
                )}

                <OrganizationsSection
                  afterEvent={afterEvent}
                  event={event}
                  eventOwner={eventOwner}
                  isAdmin={canEditAdmin}
                  canEdit={canEditOrganization}
                  onSave={handleSave}
                />

                <ContactSection
                  event={event}
                  canEdit={activeEditMode}
                  isAdmin={canEditAdmin}
                  onSave={handleSave}
                />

                <DisciplineSection event={event} canEdit={activeEditMode} onSave={handleSave} />
              </div>
            </EventDrawer>
          </div>

          <SuccessCreateMercadoPagoAuthorizationDialog
            open={openSuccessCreateMercadoPagoAuthorization}
            onAccept={handleSuccessCreateMercadoPagoAuthorization}
          />
          <MercadoPagoCancelledPaymentDialog
            open={openMercadoPagoCancelledPaymentDialog}
            onAccept={handleCloseMercadoPagoCancelledPaymentDialog}
          />
          <SuccessInscriptionDialog
            open={openSuccessCreateInscriptionForMercadoPago}
            onClose={handleSuccessCreateInscriptionForMercadoPago}
          />

          <EventCreatedDialog
            open={openEventCreatedDialog}
            onClose={() => {
              history.replace(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)
              setOpenEventCreatedDialog(false)
            }}
          />

          <EventPublishedDialog
            open={openEventPublishedDialog}
            onClose={() => setOpenEventPublishedDialog(false)}
          />
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />

      <PageContainer altMode>{activeLoading ? renderLoading() : renderProfilePage()}</PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default ProfilePage
